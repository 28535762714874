<template>
    <div>
        <template v-if="!show_tfa_page">
            <div class="section mt-2 text-center">
                <img class="auth-logo" src="../../assets/img/metriclogo_color.png" alt="metrics logo" style="">
                <h3 class="mt-3">Log in</h3>
                <!--            <h4>Fill the form to log in</h4>-->
            </div>
            <div class="section mb-5 p-2">

                <form role="form" @submit.prevent="proceed">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">E-mail</label>
                                    <input type="email" v-model="email" class="form-control" id="email1" placeholder="Your e-mail">
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password1">Password</label>
                                    <input type="password" v-model="password" class="form-control" id="password1" autocomplete="off"
                                           placeholder="Your password">
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-links mt-2">
                        <div class="col">
                            <router-link :to="{name: 'RecoverAssets'}" class="text-muted float-start">Recover Assets</router-link>
                        </div>
                        <div class="col">
                            <router-link :to="{name: 'ResetPassword'}" class="text-muted float-end">
                                Forgot Password?
                            </router-link>
                        </div>
                    </div>

                    <div class="form-button-group transparent">
                        <button type="submit" class="btn btn-primary btn-block btn-lg">Log in</button>
                    </div>

                </form>
            </div>
        </template>

        <template v-else>
            <div class="section mt-2 text-center">
                <h1>Enter SMS Code</h1>
                <h4>Enter the 4 digit sms verification code sent to your phone or email</h4>
            </div>
            <div class="section mb-5 p-2">
                <form @submit.prevent="continueLogin">
                    <div class="form-group basic">
                        <input v-model="tfa_code_input" type="text" class="form-control verification-input" id="smscode" placeholder="••••"
                               maxlength="4">
                    </div>

                    <div class="form-button-group transparent">
                        <button type="submit" class="btn btn-primary btn-block btn-lg">Verify</button>
                    </div>

                </form>
            </div>
        </template>

    </div>
</template>

<script>
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "login",
    data(){
        return {
            email: '',
            password: '',
            show_tfa_page: false,
            tfa_code_input: '',
            tfa_code_received: ''
        }
    },
    mixins: [methodsMixin],
    watch: {
        show_tfa_page(newVal){
            if(!newVal){
                this.tfa_code_input = this.tfa_code_received = '';
            }
        }
    },
    methods: {
        async proceed(){
            // return
            if(this.validateFields() === 'done'){
                this.$loader.show()
                const {email, password} = this;
                let response = await this.$store.dispatch('auth/login', {
                    email, password
                });
                this.$loader.hide()
                if(response.status){
                    if(response.data.tfa_enabled){
                        this.tfa_code_received = response.data.tfa_code;
                        this.show_tfa_page = true;
                    }else{
                        this.$store.dispatch('notification/display_noti', {
                            message: 'Login Successful!',
                            timeout: 1500
                        });
                        this.$store.commit('auth/setTFAModal', true);
                        if(sessionStorage.getItem('RE-NEW')){
                            sessionStorage.removeItem('RE-NEW');
                            this.$store.commit('auth/setPassphraseModal', true);
                        }
                        return setTimeout(()=>{
                            this.$router.replace({name: 'MetricsWallet'});
                        }, 2000);
                    }
                }else{
                    console.log(response.message);
                    return this.$store.dispatch('notification/display_noti', {
                        message: "Unable to complete request",
                    })
                }
            }
        },
        async continueLogin(){
            if(this.tfa_code_received === this.tfa_code_input){
                const {email, password} = this;
                this.$loader.show();
                let response = await this.$store.dispatch('auth/loginAfterTFA', {
                    email, password
                });
                this.$loader.hide();
                if(response.status){
                    this.$store.dispatch('notification/display_noti', {
                        message: 'Login Successful!',
                        timeout: 1500
                    });
                    return setTimeout(()=>{
                        this.$router.replace({name: 'MetricsWallet'})
                    }, 2000);
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    })
                }
            }else{
                this.$store.dispatch('notification/display_noti', {
                    message: 'Invalid verification code!',
                });
            }
        },
        validateFields(){
            if(!this.email || !this.password){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Complete all input fields!',
                    timeout: 1500
                });
            }
            if(!this.validateEmail(this.email)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Invalid email address',
                    timeout: 1500
                });
            }
            if(this.password.length <= 7){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Incorrect password',
                    timeout: 1500
                });
            }
            return 'done'
        }
    }
}
</script>

<style scoped>
.auth-logo{
    width: 100px;
    height: 100px
}
</style>